
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// pages/_error.tsx

import React from "react";
import Link from "next/link";
import { NextPageContext } from "next";
import { IconError404, IconExclamationCircle } from "@tabler/icons-react";

interface ErrorProps {
  statusCode: number | undefined;
}

const Error = ({ statusCode }: ErrorProps) => {
  return (
    <div className="bg-pizza-blue w-full h-screen font-futura flex items-center justify-center flex-col px-3">
      <h1 className="font-hand font-bold text-5xl flex items-center">
        <IconExclamationCircle className="mr-2" size={55}/> {statusCode}
      </h1>
      <h1>
        {statusCode
          ? statusCode !== 404
            ? `An error occurred on the server`
            : ""
          : "An error occurred on the client"}
      </h1>
      {statusCode === 404 && (
        <p>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
      )}
      <Link
        href="/"
        className="w-full uppercase md:w-auto flex
         items-center justify-center transition-all 
         ease-in duration-75 bg-pizza-yellow-darker
          dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75 text-pizza-papaya  
          active:shadow-inner px-5 py-2.5 text-center mt-6"
      >
        Go back home
      </Link>
    </div>
  );
};

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const __Page_Next_Translate__ = Error;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  